// CommonGlobalLoader.js
import React from "react";
import { Box } from "@mui/material";
import BeatLoader from "react-spinners/BeatLoader";
import { useLoader } from "../../context/LoaderContext";

const CommonGlobalLoader = () => {
  const { isLoading } = useLoader();

  if (!isLoading) return null;

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
      }}
    >
      {/* <BeatLoader color="#ffffff" size={15} margin={5} /> */}
      <BeatLoader color="#FFD700" size={20} margin={10} />
    </Box>
  );
};

export default CommonGlobalLoader;
