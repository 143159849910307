import {
  Badge,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  Button
} from "@mui/material";
import React from "react";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import DeleteIcon from '@mui/icons-material/Delete';
import Menu from "@mui/material/Menu";
import Login from "../common/login/Login";
import { useCart } from "../../context/CartContext";
import CartModel from "../common/CartModel";
import { useSelector } from "react-redux";
import { selectData } from "../../store/reducers/settings";
import { t } from "i18next";
import AddOrRemoveCartItem from "./AddOrRemoveCartItem";

const OfflineCart = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const data = useSelector(selectData);
  const currency = data && data['currency'] ? data['currency'] : '$';
  console.log('test ', data);
  const { Remove_data, usercart, cartTotal, ClearOfflineCart } = useCart();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const Remove = (product_variant_id) => {
    Remove_data(product_variant_id);
  };

  const ClearCart = () => {
    ClearOfflineCart();
  };

  return (
    <div>
      <Tooltip title={t("cart")}>
        <IconButton className="icon-custom-cls" size="large" color="inherit" onClick={handleClick}>
          <Badge
            badgeContent={cartTotal != "" ? cartTotal : null}
            color="error"
          >
            <ShoppingCartIcon />
          </Badge>
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button"
        }}
      >
        <div style={{ padding: "15px" }}>
          {usercart != null && usercart != "" ? (
            <div>
              {usercart &&
                usercart.map((cart, index) => {
                  const {
                    image,
                    title,
                    price,
                    qty,
                    product_variant_id,
                    minimum_order_quantity,
                    total_allowed_quantity,
                    short_description,
                    indicator,
                    addons,
                    variants,
                    rating,
                    is_restro_open,
                    partner_id
                  } = cart;
                  
                  const variantId = variants && variants.length > 0 ? variants[0].id : "";

                  return (
                    <div key={index}>
                      <Grid container spacing={1}>
                        <Grid item md={3}>
                          <div className="offline-product-img-wrapper">
                            <img src={image} alt="product" />
                          </div>
                        </Grid>
                        <Grid item md={9}>
                          <div className="offline-product-wrapper">
                            <Typography variant="subtitle1" component="h5">
                              {title}
                            </Typography>

                            <Grid container>

                              <Grid item md={5}>
                                <AddOrRemoveCartItem
                                  item_price={price}
                                  variantId={variantId || product_variant_id}
                                  addons_id={addons && addons.length > 0 ? addons.map((item) => item.add_on_id).join(", ") : ""}
                                  addons={addons}
                                  short_description={short_description}
                                  name={title}
                                  image={image}
                                  partner_id={partner_id}
                                  total_quantity={total_allowed_quantity || 10}
                                  minimum_order_quantity={minimum_order_quantity || 1}
                                  variants={variants}
                                  indicator={indicator}
                                  rating={rating}
                                  is_restro_open={is_restro_open}
                                  id={product_variant_id}
                                  product_qty={qty}
                                  justifyContent={"start"}
                                />
                              </Grid>

                              <Grid item md={7}
                                sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <Typography variant="subtitle1" component="h5">
                                  <span>x</span>
                                  <span> {currency}  {price}  </span>
                                </Typography>
                                <Typography variant="subtitle1" component="h5" sx={{
                                  textAlign: "right",
                                }}>
                                  <span> {currency} {price * qty} </span>
                                </Typography>
                              </Grid>

                              {/* <Grid item md={6}>
                                <Typography variant="subtitle1" component="h5">
                                  {currency}
                                  {price*qty}
                                </Typography>
                              </Grid> */}
                            </Grid>
                          </div>
                        </Grid>
                      </Grid>
                      <div className="border" />
                    </div>
                  );
                })}

              {/* <Button
                variant="contained"
                fullWidth
                color="error"
                className="mb20"
                onClick={() => ClearCart()}
              >
                Clear Cart
              </Button> */}

              <div className="login" sx={{ textAlign: 'center' }}>
                <Login label={'Login to checkout'} custom_color={{
                  margin: "auto",
                  cursor: "pointer",
                  background: '#5dca62',
                  padding: '5px',
                  borderRadius: '5px',
                  textAlign: 'center',
                  color: '#fff !important',
                  '&:hover': {
                    background: '#3fca46'
                  }
                }} />
              </div>

              {/* <Typography variant="subtitle1" component="h5">
                Login to Checkout Cart Items
              </Typography> */}

            </div>
          ) : (
            <div className="empty_cart" style={{ padding: "20px" }}>
              <Typography> your cart is empty!</Typography>
            </div>
          )}
        </div>
      </Menu>
    </div>
  );
};

export default OfflineCart;
