import { useContext, useState, createContext } from "react";
import { toast } from "react-hot-toast";
import * as api from "../utils/api";
import { useSelector } from "react-redux";
import { useCustomModal } from "./CustomModalContext";
import { generateOrderDate } from "../utils/functions";
import { useCart } from "./CartContext";

const PayContext = createContext();

export function usePayment() {
  return useContext(PayContext);
}

export function PaymentContext({ children }) {
  const [orders, setOrders] = useState();
  const [load, setLoad] = useState(false);

  const wallet = useSelector((state) => state.iswalletused)?.isWalletUsed;
  const userWallet = useSelector((state) => state.wallet)?.wallet;
  let isUsed = wallet.isUsed;

  const { openModal } = useCustomModal();

  const {
    clearCart, // eslint-disable-next-line
  } = useCart();

  const handleOrderSuccess = async (order_id, selectedSlot) => {
    await clearCart();
    // Testing purpose ----> generateOrderDate() || "November 19, 2024 10AM - 2PM";
    const orderDate = `${selectedSlot['date']} ${selectedSlot['slot']['time']}`;
    openModal({
      orderId: order_id, /* "SDM1209991", */
      deliveryDate: orderDate,
    });
  };

  const clearLoader = () => {
    setLoad(false);
  };

  const Place_Order = (
    variant_id,
    qty,
    total,
    wallet,
    payment_method,
    active_status,
    address,
    delivery_tip = 0,
    type,
    selectedSlot = null,
    promo_code = null
  ) => {
    api
      .place_order(
        variant_id,
        qty,
        total,
        isUsed === true ? 1 : 0,
        payment_method,
        active_status,
        address,
        delivery_tip,
        "",
        "",
        "",
        parseFloat(userWallet[0].balance) - parseFloat(total),
        localStorage.getItem("order_note"),
        selectedSlot,
        promo_code
      )
      .then((response) => {
        if (response.order_id == null) {
          return null;
        } else {
          clearLoader();
          localStorage.setItem("order_id", response.order_id);
          setOrders(response.order_id);
          var test = api.payment_intent(response.order_id, type).then((res) => {
            console.log(res);
            localStorage.setItem("client_id", res.data.id);
          });
          console.log(test);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //   add_transactions

  const transactions = (order_id, type, payment_method, txn_id, amount, selectedSlot = null) => {
    api
      .add_transaction(
        order_id,
        type,
        payment_method,
        txn_id,
        amount,
        "success",
        "done",
        selectedSlot['slot']
      )
      .then(() => {
        toast("Congratulations Order Placed Successfully..");
        handleOrderSuccess(order_id, selectedSlot);
        // navigate("/confirmed", { state: { orderId: order_id } });
        // window.location.reload();
        localStorage.removeItem("order_id");
      });
  };

  const value = {
    Place_Order,
    orders,
    transactions,
    setLoad,
    load,
  };

  return <PayContext.Provider value={value}>{children}</PayContext.Provider>;
}
