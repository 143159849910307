import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";

const OrderStatusChip = ({ active_status }) => {
    const { t } = useTranslation();

    const statusConfig = {
        preparing: { color: "secondary", label: "Preparing" },
        pending: { color: "warning", label: "Pending" },
        awaiting: { color: "warning", label: "Pending" },
        delivered: { color: "success", label: "Delivered" },
        out_for_delivery: { color: "info", label: "Out for Delivery" },
        cancelled: { color: "error", label: "Cancelled" },
    };

    const { color = "default", label = active_status } = statusConfig[active_status] || {};

    return (
        <Chip
            label={label}
            color={color}
            sx={{
                fontWeight: "600",
                minWidth: "90px",
            }}
            variant="outlined"
        />
    );

};

export default OrderStatusChip;
