import React from "react";
import { Box, Typography } from "@mui/material";

const LoginBanner = ({ newUserscreen }) => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: 0,
                // backgroundColor: "#a62947", // Dark red background color
                height: "220px", // Adjust the height to match the banner
                position: "relative",
                color: "#fff",
                textAlign: "center",
                backgroundImage: `url("${process.env.PUBLIC_URL}/images/login-bg.jpg")`, // Background pattern
                backgroundRepeat: "repeat", // Ensure the pattern repeats
                backgroundSize: "cover", // Cover the entire area
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px", // Add subtle rounding if needed
                // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
            }}
        >
            {/* Logo */}
            <Box
                component="img"
                src={`${process.env.PUBLIC_URL}/images/logo-brand-light.png`} // Update to match your logo
                alt="Logo"
                sx={{
                    height: "80px",
                    marginBottom: "16px",
                }}
            />

            {/* Text */}
            <Typography
                variant="h5"
                sx={{
                    fontWeight: "600",
                    color: "#ffffff",
                    mb: 1,
                    fontSize: "1.25rem", // Adjust font size for better visibility
                }}
            >
                {newUserscreen ? "Sign in to" : "Login to"} {" "}
                <Typography
                    component="span"
                    sx={{
                        fontSize: "1.25rem",
                        color: "#FFD700", // Gold color for "Sameday Meats"
                        fontWeight: "600",
                    }}
                >
                    Sameday Meats
                </Typography>
            </Typography>
        </Box>
    );
};

export default LoginBanner;
